<template>
    <div class="nav">
        <div class="item" v-for="(item, i) in navList" :key="i" :class="item.path === $route.path && 'active'" @click="change(item.path, i)">
            <span>{{ item.name }}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            navList: [
                { 'path': '/', 'name': '首页' },
                { 'path': '/index', 'name': '转换工具' },
                { 'path': '/searchlist', 'name': '搜索列表' },
                { 'path': '/usercenter', 'name': '我的' }
            ]
        }
    },
    methods:{
        change(path, i){
            let currentIndex = -1
            this.navList.find((nav, index) => {
                if (nav.path === this.$route.path) {
                    currentIndex = index
                    return true
                }
            })
            if (i === currentIndex) return
            this.$routerReplace(path)
        }
    }
}
</script>

<style lang="scss" scoped>
.nav{
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 50px;
    background: #fff;
    display: flex;
    border-top: 1px solid #dddddb;
    .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #999;
    }
}
</style>