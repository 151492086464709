<template>
    <van-overlay class="super-mask" :show="show">
        <div class="wrapper" @click.stop>
            <van-loading vertical />
        </div>
    </van-overlay>
</template>

<script>
export default {
    props:{
        show:{
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.super-mask{
    background-color: rgba(0, 0, 0, 0);
    z-index: 9999;
    .wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}
</style>