<template>
    <div class="convert-wrap">
        <van-field v-model="tklContent" type="textarea" rows="4" placeholder="请输入要转换的内容" />
        <van-radio-group v-model="convertType" direction="horizontal" class="convert-type">
            <van-radio name="1" icon-size="16px">淘宝</van-radio>
            <van-radio name="2" icon-size="16px">京东</van-radio>
        </van-radio-group>
        <div class="right-align">
            <van-button class="clear-btn" plain type="info" @click="clearContent">清&nbsp;&nbsp;&nbsp;空</van-button>
            <van-button class="convert-btn" type="primary" @click="convertTKL">转换口令</van-button>
        </div>
        <van-field class="tkl-content" v-model="newTKL" type="textarea" rows="4" placeholder="转换后口令" />
        <div class="detail-info">
            <div class="detail-list">
                <ul v-if="done">
                    <li v-if="couponInfo">优惠券 {{ couponInfo || "无" }}</li>
                    <li v-if="commissionRate">佣金比率 {{ commissionRate + "%" }}</li>
                </ul>
            </div>
            <van-button class="copy-btn" type="primary" :data-clipboard-text="newTKL" @click.native="copyTKL">一键复制</van-button>
        </div>

        <loadingNow :show="!done" />
        <Navi />
    </div>
</template>

<script>
import Navi from '@/components/Navigator'
import loadingNow from "@/components/loadingNow";
import Clipboard from "clipboard";

export default {
    components: {
        Navi,
        loadingNow,
    },
    data() {
        return {
            couponInfo: "",
            commissionRate: "",
            tklContent: "",
            newTKL: "",
            done: true,
            convertType: '1',
            relation_id: ''
        };
    },
    created() {
        this.relation_id = this.$store.state.user.userInfo.relation_id
    },
    methods: {
        convertTKL() {
            if(this.tklContent==''){
                this.$toast("内容不能为空");
                return false;
            }
            this.newTKL = "";
            this.done = false;
            if(this.convertType==1){
                let params = {tkl:encodeURIComponent(this.tklContent)}
                if(this.relation_id) params.relation_id = this.relation_id
                this.$ask.post('/convertTKL', params)
                    .then(res => {
                        const resData = res.data
                        this.done = true;
                        this.newTKL = `${resData.tkl} ${resData.shorturl2} ${resData.title}`
                        this.commissionRate = resData.tkrate3
                        this.couponInfo = resData.coupon_info
                    })
                    .catch(err => {
                        this.done = true;
                        if(err.msg==301){
                            this.$toast('您复制的商品暂时无法完成转链!')
                            this.couponInfo = "";
                            this.commissionRate = "";
                        }
                        else this.$toastError(err)
                    });
            }
            else{
                let params = {oUrl: this.tklContent}
                if(this.relation_id) params.positionId = this.relation_id
                this.$ask.post('/convertJDKL', params)
                    .then(res => {
                        const resData = res.data
                        this.done = true;
                        this.newTKL = resData.shorturl
                        this.commissionRate = resData.tkrate3
                        this.couponInfo = resData.coupon_info
                    })
                    .catch(err => {
                        this.done = true;
                        if(err.msg==301){
                            this.$toast('您复制的商品暂时无法完成转链!')
                            this.couponInfo = "";
                            this.commissionRate = "";
                        }
                        else this.$toastError(err)
                    });
            }
        },
        copyTKL() {
            if (this.newTKL == "") {
                this.$toast("请先生成口令");
            }
            let clipboard = new Clipboard(".copy-btn");
            let self = this;
            clipboard.on("success", () => {
                self.$toast("复制成功");
                clipboard.destroy();
            });
            clipboard.on("error", () => {
                self.$toast("该浏览器不支持自动复制");
                clipboard.destroy();
            });
        },
        clearContent() {
            this.tklContent = "";
            this.newTKL = "";
            this.couponInfo = "";
            this.commissionRate = "";
        },
    },
};
</script>

<style lang="scss" scoped>
.inner {
    margin-top: 20px;
}
.convert-wrap {
    padding: 20px;
    min-height: 100vh;
    overflow-y: auto;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #f6f6f6;
    .convert-type{
        margin-top: 10px;
    }
    .convert-btn {
        margin: 20px 0 20px 20px;
    }
    .right-align {
        text-align: right;
    }
}
.detail-info {
    padding-top: 20px;
    display: flex;
}
.detail-list {
    flex: 1;
    line-height: 28px;
}
</style>
